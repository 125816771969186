// NPM
import * as React from "react"
import { graphql } from 'gatsby'
import _ from 'lodash'

// Components
import Layout from "src/layouts/en"
import VoteCompass from "src/components/pages/VoteCompass/VoteCompass"

const VoteCompassPageEn = ({ location, data }) => {
  const topNav = data.topNav
  const sections = _.get(data, `voteCompassPage.sections`, [])
  const voteCompasses = _.get(data, `voteCompasses.nodes`, [])
  const newsItems = _.get(data, `newsItems.nodes`, [])
  const bottomNav = data.bottomNav
  const socialMedia = _.get(data, `socialMedia.nodes`, [])

  return (
    <Layout 
      location={ location }
      topNav={ topNav }
      page={ data.voteCompassPage }
      bottomNav={ bottomNav }
      socialMedia={ socialMedia }>
        <VoteCompass 
          sections={ sections } 
          voteCompasses={ voteCompasses }
          newsItems={ newsItems }
        />
    </Layout>
  )
}

export default VoteCompassPageEn

export const query = graphql`
  query VoteCompassPageEnQuery($id: String) {
    topNav: strapiTopNav(locale: {eq: "en"}) {
      ...topNavContent
    }
    
    voteCompassPage: strapiPage(id: {eq: $id}) {
      ...pageInformation
    }

    voteCompasses: allStrapiVoteCompass(filter: {locale: {eq: "en"}}) {
      nodes {
        name
        year
        region
        subRegion
        url
      }
    }
    
    newsItems: allStrapiNewsItem {
      nodes {
        ...newsItem
      }
    }

    bottomNav: strapiBottomNav(locale: {eq: "en"}) {
      ...bottomNavContent
    }

    socialMedia: allStrapiSocialMedia(filter: {locale: {eq: "en"}}) {
      nodes {
        ...socialMedia
      }
    }



  }
`