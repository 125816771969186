// NPM
import React, { useState, useRef } from 'react'
import Select from 'react-select'
import _ from 'lodash'

// Shared components
import Description from 'src/components/Shared/Description'
import Pagination from "src/components/Shared/Pagination"

// Component
import VoteCompassInitiative from './VoteCompassInitiative'

// Constants
import reactSelectStyles from 'src/components/Shared/reactSelectStyles'

const VoteCompassInitiatives = ({ sections, voteCompasses }) => {
  const [searchOptions, setSearchOptions] = useState({})
  const section = sections.find(s => s.uuid === "voteCompass_initiatives")

  if(!section) {
    return null
  }

  const visibleVoteCompasses = 
    voteCompasses
    .filter(vc => {
      if(searchOptions.year && searchOptions.year.value) {
        return vc.year === searchOptions.year.value
      }
      else {
        // Not filtering by year, return all
        return true
      }
    })
    .filter(vc => {
      if(searchOptions.region && searchOptions.region.value) {
        let [region, subRegion] = searchOptions.region.value.split("_")
        return vc.region === region && vc.subRegion === subRegion
      }
      else {
        // Not filtering by year, return all
        return true
      }
    })

  const groupedVoteCompasses = _.groupBy(visibleVoteCompasses, "year");

  const renderVoteCompassGroup = groupKey => {
    const groupItems = _.sortBy(groupedVoteCompasses[groupKey], "order")

    return (
      <div 
        className="VoteCompassInitiatives__group"
        key={ `VoteCompassInitiatives__group-${ groupKey }` }>
          <h4 className="VoteCompassInitiatives__group-title">
            { groupKey }
          </h4>

          <div className="VoteCompassInitiatives__group-items">
            { 
              groupItems.map((voteCompass, voteCompassIndex) => {
                return (
                  <VoteCompassInitiative 
                    voteCompass={ voteCompass }
                    key={ `VoteCompass-${ voteCompassIndex }` }
                  />
                )
              })
            }
          </div>
      </div>
    )
  }

  const renderYearDropdown = () => {
    const allGroupedVoteCompasses = _.groupBy(visibleVoteCompasses, "year");
    const options = Object.keys(allGroupedVoteCompasses)
      .sort()
      .reverse()
      .map(year => ({label: year, value: year}))

    const placeholderText = (section.miscText.find(mt => mt.uuid === "yearDropdownPlaceholder") || {}).text
    const allText = (section.miscText.find(mt => mt.uuid === "dropdownAllText") || {}).text

    return (
      <Select 
        options={[
          { label: allText, value: -1 },
          ...options
        ]} 
        placeholder={ placeholderText }
        className="VoteCompassInitiatives__search-select"
        value={ searchOptions.year }
        onChange={ selectedOption => { 
          if(selectedOption) {
            if(selectedOption.value === -1) {
              setSearchOptions({...searchOptions, year: null}) 
            }
            else {
              setSearchOptions({...searchOptions, year: selectedOption}) 
            }
          }
        }}
        styles={ reactSelectStyles }
      />
    )
  }

  const renderRegionDropdown = () => {
    const uniqRegions = _.uniq(visibleVoteCompasses.map(vc => vc.region))
    const options = 
      uniqRegions
      .sort()
      .map(region => {
        let subRegions = 
          visibleVoteCompasses
          .filter(vc => vc.region === region)
          .map(vc => vc.subRegion)

        let uniqSubRegions = _.uniq(subRegions)
        return {
          label: region, 
          options: (
            uniqSubRegions
            .sort()
            .map(subRegion => ({ label: subRegion, value: `${ region }_${ subRegion }` }))
          )
        }
      })

    const placeholderText = (section.miscText.find(mt => mt.uuid === "regionDropdownPlaceholder") || {}).text
    const allText = (section.miscText.find(mt => mt.uuid === "dropdownAllText") || {}).text

    return (
      <Select 
        options={[
          { label: allText, value: -1 },
          ...options
        ]} 
        value={ searchOptions.region }
        placeholder={ placeholderText }
        className="VoteCompassInitiatives__search-select"
        onChange={ selectedOption => { 
          if(selectedOption) {
            if(selectedOption.value === -1) {
              setSearchOptions({...searchOptions, region: null}) 
            }
            else {
              setSearchOptions({...searchOptions, region: selectedOption}) 
            }
          }
        }}
        styles={{
          ...reactSelectStyles,
          groupHeading: (provided) => ({
            ...provided,
            fontFamily: "Satoshi",
            color: "black",
            fontWeight: "bold"
          }),
          option: (provided, state) => {
            return {
              ...provided,
              fontFamily: "Satoshi",
              paddingLeft: state.data.value === -1 ? "15px" : "30px"
            }
          }
        }}
      />
    )
  }

  return (
    <div className="VoteCompassInitiatives" id="VoteCompassInitiatives">
      <div className="VoteCompassInitiatives__container">
        <h3 className="VoteCompassInitiatives__title">
          { section.title }
        </h3>

        <Description
          className="VoteCompassInitiatives__description"
          description={ section.description }
        />

        <div className="VoteCompassInitiatives__search">
          { renderYearDropdown() }
          { renderRegionDropdown() }
        </div>

        <div className="VoteCompassInitiatives__list">
          <Pagination 
            perPage={ 3 }
            items={ Object.keys(groupedVoteCompasses).sort().reverse() }>
              { (items) => items.map(renderVoteCompassGroup) }
          </Pagination>
        </div>
      </div>
    </div>
  )

}

export default VoteCompassInitiatives