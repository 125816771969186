// NPM
import React from 'react'

// Shared Components
import GenericProduct from 'src/components/pages/GenericProduct/GenericProduct'
import Timeline from 'src/components/Shared/Timeline'
import InfoColumns from 'src/components/Shared/InfoColumns'
import Statistics from 'src/components/Shared/Statistics'
import WorkTogether from 'src/components/Shared/WorkTogether'
import NewsItems from 'src/components/Shared/NewsItems'
import Partners from 'src/components/Shared/Partners'
import SimpleSection from 'src/components/Shared/SimpleSection'

// Components
import VoteCompassInitiatives from './VoteCompassInitiatives'

const VoteCompass = ({ sections, voteCompasses, newsItems }) => {
  const infoColumnsTitleSection = sections.find(s => s.uuid === "infoColumns_title")

  return (
    <div className="VoteCompass">
      <GenericProduct sections={ sections } />
      <Partners section={ sections.find(s => s.uuid === "partners") } />
      <Timeline section={ sections.find(s => s.uuid === "timeline") } />

      {
        infoColumnsTitleSection &&
          <h3 className="VoteCompass__infoColumnsTitle section-header small-header">
            { infoColumnsTitleSection.title }
          </h3>
      }

      <InfoColumns sections={ sections } />
      <Statistics section={ sections.find(s => s.uuid === "statistics") } />
      <SimpleSection section={ sections.find(s => s.uuid === "methodology") } />
      <VoteCompassInitiatives 
        sections={ sections } 
        voteCompasses={ voteCompasses }
      />
      <NewsItems 
        section={ sections.find(s => s.uuid === "newsItems") }
        newsItems={ newsItems }
        newsItemSearchKey="voteCompass"
      />
      <WorkTogether section={ sections.find(s => s.uuid === "workTogether") } />
    </div>
  )

}

export default VoteCompass