// NPM
import React from 'react'

const VoteCompassInitiative = ({ voteCompass }) => {

  const isUnavailable = !voteCompass.url

  if(isUnavailable) {
    return (
      <div className="VoteCompassInitiative is-unavailable">
        <h4 className="VoteCompassInitiative__title">
          { voteCompass.name }
        </h4>
      </div>
    )
  }
  else {
    return (
      <a 
        className="VoteCompassInitiative"
        target="_blank"
        href={ voteCompass.url }>
          <h4 className="VoteCompassInitiative__title">
            { voteCompass.name }
          </h4>

          <img 
            className="VoteCompassInitiative__icon"
            src={ require("src/images/icons/arrow-right-black.png").default }
            aria-hidden="true"
          />
      </a>
    )
  }

}

export default VoteCompassInitiative